import { UcoastEl } from '@/scripts/core/UcoastEl'
import {getBackendRoute, notifyMeConfig, qsOptional, qsRequired} from "@/scripts/core/global";
import {KlaviyoOosPopup} from "@/scripts/theme/klaviyo-oos-popup";

export class KlaviyoOosForm extends UcoastEl {
    static htmlSelector = 'klaviyo-oos-form'
    form: HTMLFormElement
    submitButton: HTMLButtonElement
    emailInput: HTMLInputElement
    successEl: HTMLElement
    route: string
    popup?: KlaviyoOosPopup
    variant?: string
    constructor() {
        super()
        this.form = qsRequired('form', this)
        this.submitButton = qsRequired('[type="submit"]', this)
        this.emailInput = qsRequired('[type="email"]', this)
        this.successEl = qsRequired('[data-uc-form-status]', this)
        this.route = `${getBackendRoute()}/api/klaviyo-oos`
        this.form.addEventListener('submit', this.onSubmitHandler.bind(this))
        this.popup = qsOptional<KlaviyoOosPopup>('notify-me', this)
    }

    onSubmitHandler(event: Event) {
        event.preventDefault()
        if (this.submitButton.getAttribute('aria-disabled') === 'true') return
        if (this.emailInput.value.length < 1) return

        this.submitButton.setAttribute('aria-disabled', 'true')
        this.submitButton.classList.add('loading')

        const formData = new FormData(this.form)

        const config = notifyMeConfig(formData)

        fetch(this.route, config)
            .then((response) => response.json())
            .then((response) => {
                this.emailInput.value = ''
                if (response.success) {
                    this.successEl.classList.remove('hidden')
                    this.submitButton.classList.add('success')
                    this.emailInput.placeholder = 'Thanks for signing up'
                    if (this.popup) {
                        window.setTimeout(() => {
                            this.popup?.hide()
                        }, 5000)
                    }
                } else {
                    this.emailInput.placeholder = 'This email address is invalid.'
                }
            })
            .catch((e) => {
                this.emailInput.placeholder = 'This email address is invalid.'
                console.error(e)
            })
            .finally(() => {
                this.submitButton.classList.remove('loading')
                this.submitButton.removeAttribute('aria-disabled')
            })
    }
}
