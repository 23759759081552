import { PUB_SUB_EVENTS } from '@/scripts/core/global'
import { isVariantChangeEvent, publish, subscribe } from '@/scripts/core/global'
import { type VariantRadios } from '@/scripts/theme/variant-radios'
import { getAttributeOrThrow, qsOptional, qsRequired } from '@/scripts/core/global'
import { UcoastEl } from '@/scripts/core/UcoastEl'
import { ProductStickyCta } from '@/scripts/product/product-sticky-cta'

export class ProductInfo extends UcoastEl {
	static htmlSelector = 'product-info'
	static selectors = {
		quantityForm: '[data-uc-product-form-quantity]',
		quantityInput: '[data-uc-quantity-input]',
		quantityRules: '[data-uc-quantity-rules]',
		quantityRulesCart: '[data-uc-quantity-rules-cart]',
		quantityLabel: '[data-uc-quantity-label]',
		currentVariant: '[data-uc-product-variant-id]',
		variantRadios: 'variant-radios',
		submitButton: '[type="submit"]',
	}
	source: string
	productId: string
	quantityInput?: HTMLInputElement
	currentVariant: HTMLInputElement
	variantRadios?: VariantRadios
	submitButton: HTMLButtonElement
	quantityForm?: HTMLFormElement
	siblingForm?: ProductInfo | ProductStickyCta
	cartUpdateUnsubscriber?: () => void = undefined
	variantChangeUnsubscriber?: () => void = undefined

	constructor() {
		super()
		this.quantityInput = qsOptional(ProductInfo.selectors.quantityInput, this)
		this.currentVariant = qsRequired(ProductInfo.selectors.currentVariant, this)
		this.variantRadios = qsOptional(ProductInfo.selectors.variantRadios, this)
		this.submitButton = qsRequired(ProductInfo.selectors.submitButton, this)
		this.source = getAttributeOrThrow('data-source', this)
		this.productId = getAttributeOrThrow('data-uc-product-id', this)
		this.siblingForm = this.getSiblingForm()
	}

	override connectedCallback() {
		if (!this.quantityInput) return
		this.quantityForm = qsOptional(ProductInfo.selectors.quantityForm, this)
		if (!this.quantityForm) return
		this.setQuantityBoundries()
		if (!this.dataset.originalSection) {
			this.cartUpdateUnsubscriber = subscribe(PUB_SUB_EVENTS.cartUpdate, this.fetchQuantityRules.bind(this))
		}
		this.variantChangeUnsubscriber = subscribe(PUB_SUB_EVENTS.variantChange, (event) => {
			if (!isVariantChangeEvent(event)) return
			const sectionId = this.dataset.originalSection ? this.dataset.originalSection : this.dataset.section
			if (event.data.sectionId !== sectionId) return
			this.updateQuantityRules(event.data.sectionId, event.data.html)
			this.setQuantityBoundries()
		})
	}

	override disconnectedCallback() {
		if (this.cartUpdateUnsubscriber) {
			this.cartUpdateUnsubscriber()
		}
		if (this.variantChangeUnsubscriber) {
			this.variantChangeUnsubscriber()
		}
	}

	setQuantityBoundries() {
		if (!this.quantityInput) return
		const data = {
			cartQuantity: this.quantityInput.dataset.cartQuantity
				? parseInt(this.quantityInput.dataset.cartQuantity)
				: 0,
			min: this.quantityInput.dataset.min ? parseInt(this.quantityInput.dataset.min) : 1,
			max: this.quantityInput.dataset.max ? parseInt(this.quantityInput.dataset.max) : null,
			step: this.quantityInput.step ? parseInt(this.quantityInput.step) : 1,
		}

		let min = data.min
		const max = data.max === null ? data.max : data.max - data.cartQuantity
		if (max !== null) min = Math.min(min, max)
		if (data.cartQuantity >= data.min) min = Math.min(min, data.step)

		this.quantityInput.min = `${min}`
		this.quantityInput.max = `${max}`
		this.quantityInput.value = `${min}`
		publish(PUB_SUB_EVENTS.quantityUpdate, undefined)
	}

	fetchQuantityRules() {
		if (!this.currentVariant || !this.currentVariant.value) return
		qsRequired('[data-uc-quantity-rules-cart] [data-uc-loading-overlay]', this).classList.remove('hidden')
		fetch(`${this.dataset.url}?variant=${this.currentVariant.value}&section_id=${this.dataset.section}`)
			.then((response) => {
				return response.text()
			})
			.then((responseText) => {
				const html = new DOMParser().parseFromString(responseText, 'text/html')
				const sectionId = getAttributeOrThrow('data-section', this)
				this.updateQuantityRules(sectionId, html)
				this.setQuantityBoundries()
			})
			.catch((e) => {
				console.error(e)
			})
			.finally(() => {
				qsRequired('[data-uc-quantity-rules-cart] [data-uc-loading-overlay]', this).classList.add('hidden')
			})
	}

	updateQuantityRules(sectionId: string, html: Document) {
		const quantityFormUpdated = html.getElementById(`Quantity-Form-${sectionId}`)
		const selectors = [
			ProductInfo.selectors.quantityInput,
			ProductInfo.selectors.quantityRules,
			ProductInfo.selectors.quantityLabel,
		]
		for (let selector of selectors) {
			if (!quantityFormUpdated) continue
			const current = qsOptional(selector, this.quantityForm)
			const updated = qsOptional(selector, quantityFormUpdated)
			if (!current || !updated) continue
			if (selector === ProductInfo.selectors.quantityInput) {
				const attributes = ['data-cart-quantity', 'data-min', 'data-max', 'step']
				for (let attribute of attributes) {
					const valueUpdated = updated.getAttribute(attribute)
					if (valueUpdated !== null) current.setAttribute(attribute, valueUpdated)
				}
			} else {
				current.innerHTML = updated.innerHTML
			}
		}
	}

	getSiblingForm() {
		return qsOptional<ProductStickyCta>(`product-sticky-cta[data-uc-product-id="${this.productId}"]`)
	}

	updateSiblingForm() {
		if (!this.variantRadios) {
			throw new Error('should not be able to trigger update without radios')
		}
		if (!this.siblingForm) {
			throw new Error('should not be triggered, this element has no sibling form')
		}
		const selectedOptions = this.variantRadios.getSelectedOptions()
		const siblingFormRadios = this.siblingForm?.variantRadios
		if (!siblingFormRadios) {
			throw new Error('should not be triggered, this elements sibling form has no radios')
		}
		siblingFormRadios.updateOptionsFromExternalSource(selectedOptions)
	}
}
