import {
	currentTargetRequired,
	getAttributeOrThrow,
	qsaOptional,
	qsOptional,
	qsRequired,
} from '@/scripts/core/global'
import { type SlideChangedEvent } from '@/scripts/types/events'
import { UcoastEl } from '@/scripts/core/UcoastEl'

export function isSliderComponent(obj: HTMLElement): obj is SliderComponent {
	if (!obj) return false
	if (obj.localName !== 'slider-component') return false
	return true
}

export class SliderComponent extends UcoastEl {
	static htmlSelector = 'slider-component'
	slider: HTMLElement
	sliderItems?: NodeListOf<HTMLElement>
	sliderItemsToShow?: HTMLElement[]
	sliderItemOffset?: number
	slidesPerPage?: number
	totalPages?: number
	currentPage?: number = 1
	slideScrollPosition?: number
	enableSliderLooping: boolean
	currentPageElement?: HTMLElement
	pageTotalElement?: HTMLElement
	prevButton?: HTMLButtonElement
	nextButton?: HTMLButtonElement
	sliderBar?: HTMLElement
	deltaX: number
	slideWidth?: number
	lastHref?: string
	constructor() {
		super()
		this.deltaX = 0
		this.slider = qsRequired('[id^="Slider-"]', this)
		this.sliderItems = qsaOptional('[id^="Slide-"]', this)
		this.enableSliderLooping = false
		this.currentPageElement = qsOptional('.slider-counter--current', this)
		this.pageTotalElement = qsOptional('.slider-counter--total', this)
		this.prevButton = qsOptional('button[name="previous"]', this)
		this.nextButton = qsOptional('button[name="next"]', this)
		this.sliderBar = qsOptional('[data-uc-slider-bar]', this)

		this.initPages()
		const resizeObserver = new ResizeObserver((_entries: ResizeObserverEntry[]) => this.initPages())
		resizeObserver.observe(this.slider)

		this.slider.addEventListener('scroll', this.update.bind(this))
		this.prevButton?.addEventListener('click', this.onButtonClick.bind(this))
		this.nextButton?.addEventListener('click', this.onButtonClick.bind(this))
		this.initDraggableSlider()
		this.sliderItems?.forEach((item) => {
			if (!this.slideWidth) {
				this.slideWidth = item.offsetWidth
			}
			const innerAnchors = qsaOptional('a', item)
			if (innerAnchors) {
				for (let i = 0; i < innerAnchors.length; i++) {
					const anchor = innerAnchors[i]
					anchor.addEventListener('click', this.preventAnchorClick.bind(this))
				}
			}
		})
	}


	preventAnchorClick(event: Event) {
		event.preventDefault()

		const currentTarget = currentTargetRequired(event)

		if (!(currentTarget instanceof HTMLAnchorElement)) {
			console.error('something is wrong - the current target here should always be an anchor element')
		}
		this.lastHref = getAttributeOrThrow('href', currentTarget)
	}

	initPages() {
		this.sliderItemsToShow = this.sliderItems
			? Array.from(this.sliderItems).filter((element) => element.clientWidth > 0)
			: []
		if (this.sliderItemsToShow.length < 2) return
		this.sliderItemOffset = this.sliderItemsToShow[1].offsetLeft - this.sliderItemsToShow[0].offsetLeft
		this.slidesPerPage = Math.floor(
			(this.slider.clientWidth - this.sliderItemsToShow[0].offsetLeft) / this.sliderItemOffset
		)
		this.totalPages = this.sliderItemsToShow.length - this.slidesPerPage + 1
		this.update()
	}

	resetPages() {
		this.sliderItems = this.querySelectorAll('[id^="Slide-"]')
		this.initPages()
	}

	createSlideChangedEvent(): SlideChangedEvent {
		const currentPage = this.currentPage
		if (!currentPage) throw new Error('cannot createSlideChangedEvent - currentPage is undefined')
		if (!this.sliderItemsToShow) throw new Error('cannot createSlideChangedEvent - sliderItemsToShow is undefined')
		const currentElement = this.sliderItemsToShow[currentPage - 1]
		if (!currentElement) throw new Error('cannot createSlideChangedEvent - currentElement is undefined')
		return new CustomEvent('slideChanged', {
			detail: {
				currentPage,
				currentElement,
			},
		})
	}

	update() {
		// Temporarily prevents unneeded updates resulting from variant changes
		// This should be refactored as part of https://github.com/Shopify/dawn/issues/2057
		if (!this.slider || !this.nextButton) return

		const previousPage = this.currentPage
		if (!this.sliderItemOffset || this.sliderItemOffset === 0) {
			this.currentPage = 1
		} else {
			this.currentPage = Math.round(this.slider.scrollLeft / this.sliderItemOffset) + 1
		}

		if (this.currentPageElement && this.pageTotalElement) {
			this.currentPageElement.textContent = `${this.currentPage}`
			this.pageTotalElement.textContent = `${this.totalPages}`
		}
		if (this.sliderBar && this.totalPages) {
			const sliderBarDesktopCount =
				parseInt(getAttributeOrThrow('data-uc-slider-bar-desktop-count', this.sliderBar)) - 1
			const sliderBarMobileCount =
				parseInt(getAttributeOrThrow('data-uc-slider-bar-mobile-count', this.sliderBar)) - 1
			const sliderBarDesktopWidth = `${((this.currentPage + sliderBarDesktopCount) / this.totalPages) * 100}%`
			const sliderBarMobileWidth = `${((this.currentPage + sliderBarMobileCount) / this.totalPages) * 100}%`
			this.sliderBar.style.setProperty('--slider-bar-desktop-width', sliderBarDesktopWidth)
			this.sliderBar.style.setProperty('--slider-bar-mobile-width', sliderBarMobileWidth)
		}

		if (this.currentPage != previousPage) {
			if (this.sliderItemsToShow) {
				this.dispatchEvent(this.createSlideChangedEvent())
			} else {
				throw new Error('sliderItemsToShow is undefined')
			}
		}

		if (this.enableSliderLooping) return

		if (
			this.prevButton &&
			this.sliderItemsToShow &&
			this.sliderItemsToShow.length &&
			this.isSlideVisible(this.sliderItemsToShow[0]) &&
			this.slider.scrollLeft === 0
		) {
			this.prevButton.setAttribute('disabled', 'disabled')
		} else if (this.prevButton) {
			this.prevButton.removeAttribute('disabled')
		}

		if (
			this.nextButton &&
			this.sliderItemsToShow &&
			this.sliderItemsToShow.length > 0 &&
			this.isSlideVisible(this.sliderItemsToShow[this.sliderItemsToShow.length - 1])
		) {
			this.nextButton.setAttribute('disabled', 'disabled')
		} else if (this.nextButton) {
			this.nextButton.removeAttribute('disabled')
		}
	}

	isSlideVisible(element: HTMLElement, offset = 0) {
		const lastVisibleSlide = this.slider.clientWidth + this.slider.scrollLeft - offset
		return (
			element.offsetLeft + element.clientWidth <= lastVisibleSlide && element.offsetLeft >= this.slider.scrollLeft
		)
	}

	onButtonClick(event: MouseEvent) {
		event.preventDefault()
		const currentTarget = currentTargetRequired(event)
		if (!(currentTarget instanceof HTMLButtonElement)) return
		if (!this.sliderItemOffset) return
		const step = currentTarget.dataset.step ? parseInt(currentTarget.dataset.step) : 1
		this.slideScrollPosition =
			currentTarget.name === 'next'
				? this.slider.scrollLeft + step * this.sliderItemOffset
				: this.slider.scrollLeft - step * this.sliderItemOffset
		this.setSlidePosition(this.slideScrollPosition)
	}

	setSlidePosition(position: number) {
		this.slider.scrollTo({
			left: position,
		})
	}

	scrollSlide(targetPosition: number) {
		let startPosition = this.slider.scrollLeft;
		let change = targetPosition - startPosition;
		let duration = 500;  // 500ms, adjust as necessary
		let startTime: number = performance.now();

		const animateScroll = (currentTime: number) => {
			let elapsedTime = currentTime - startTime;
			let progress = elapsedTime / duration;

			this.slider.scrollLeft = startPosition + change * linearEase(progress);

			if (progress < 1) {
				window.requestAnimationFrame(animateScroll);
			}
		};

		const linearEase = (t: number) => {
			return t
		};

		window.requestAnimationFrame(animateScroll);
	}

	initDraggableSlider() {
		this.slider.addEventListener('mousedown', this.onMouseDown.bind(this))
		//this.slider.addEventListener('touchstart', this.onTouchDown.bind(this), { passive: false })
	}

	getLastHref() {
		return this.lastHref
	}

	onMouseDown(event: MouseEvent) {
		const clientX = event.clientX
		//const initialScroll = this.slider.scrollLeft
		this.deltaX = 0

		const onMouseMove = (e: MouseEvent) => {
			e.preventDefault()
			const currentClientX = e.clientX
			const deltaX = clientX - currentClientX
			this.deltaX = deltaX
		}

		const onMouseUp = (event: MouseEvent) => {
			event.preventDefault()
			document.removeEventListener('mousemove', onMouseMove)
			document.removeEventListener('mouseup', onMouseUp)


			if (-10 < this.deltaX && this.deltaX < 10) {
				window.setTimeout(() => {
					if (this.getLastHref()) {
						window.location.href = this.getLastHref() ?? window.location.href
					}
				}, 2)
			} else if (this.deltaX < 0) {
				if (!this.slideWidth || !this.currentPage) {
					throw new Error('no slide found, cannot scroll slider')
				}
				const moveDistance = this.slider.scrollLeft - this.slideWidth - 5
				this.slider.scrollLeft = moveDistance
				//this.scrollSlide(moveDistance)
			} else if (this.deltaX > 0) {
				if (!this.slideWidth || !this.currentPage) {
					throw new Error('no slide found, cannot scroll slider')
				}
				const moveDistance = this.slider.scrollLeft + this.slideWidth + 5
				this.slider.scrollLeft = moveDistance
				//this.scrollSlide(moveDistance)
			}
		}

		document.addEventListener('mousemove', onMouseMove)
		document.addEventListener('mouseup', onMouseUp)
	}
}
