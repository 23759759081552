import { UcoastEl } from '@/scripts/core/UcoastEl'
import { getAttributeOrThrow, qsaRequired, qsRequired } from '@/scripts/core/global'

export class SliderCarousel extends UcoastEl {
	static htmlSelector = 'slider-carousel'
	carouselList: HTMLElement
	carouselItems: HTMLElement[]
	carouselSize: number
	carouselLimit: number
	triggers: HTMLButtonElement[]
	prevButton: HTMLButtonElement
	nextButton: HTMLButtonElement
	links: HTMLAnchorElement[]
	mobileCTAs: HTMLAnchorElement[]
	constructor() {
		super()
		this.carouselList = qsRequired('[data-uc-slider-carousel-list]', this)
		this.carouselItems = Array.from(qsaRequired('[data-uc-slider-carousel-item]', this))
		this.triggers = Array.from(qsaRequired('[data-uc-slider-carousel-trigger]', this))
		this.links = Array.from(qsaRequired('[data-uc-slider-carousel-link]', this))
		this.mobileCTAs = Array.from(qsaRequired('[data-uc-slider-carousel-mobile-cta]', this))
		this.carouselSize = this.carouselItems.length
		this.carouselLimit = this.carouselSize - 2
		this.prevButton = qsRequired('[data-uc-slider-carousel-prev]', this)
		this.nextButton = qsRequired('[data-uc-slider-carousel-next]', this)
	}

	override connectedCallback() {
		super.connectedCallback()
		this.triggers.forEach((trigger) => {
			trigger.addEventListener('click', (_: Event) => {
				const triggerPos = parseInt(getAttributeOrThrow('data-pos', trigger))
				if (triggerPos === 1) {
					this.update(-1)
				} else if (triggerPos === -1) {
					this.update(1)
				}
			})
		})
		this.prevButton.addEventListener('click', (_: Event) => {
			this.update(1)
		})
		this.nextButton.addEventListener('click', (_: Event) => {
			console.log('next')
			this.update(-1)
		})
	}

	update(newActivePos: number) {
		if (newActivePos === 0) {
			// dont update
			return
		}
		if (newActivePos !== -1 && newActivePos !== 1) {
			throw new Error('Something went wrong, incorrect index')
		}

		this.carouselItems.forEach((elem, index) => {
			const elemPos = parseInt(getAttributeOrThrow('data-pos', elem))
			const newElemPos = this.getPos(elemPos, newActivePos)
			elem.setAttribute('data-pos', newElemPos.toString())
			const relatedTrigger = this.triggers[index]
			if (!relatedTrigger) throw new Error('Something went wrong, trigger not found')
			relatedTrigger.setAttribute('data-pos', newElemPos.toString())
			const relatedMobileCTA = this.mobileCTAs[index]
			if (!relatedMobileCTA) throw new Error('Something went wrong, mobile CTA not found')
			relatedMobileCTA.setAttribute('data-pos', newElemPos.toString())
			const relatedLink = this.links[index]
			if (!relatedLink) throw new Error('Something went wrong, link not found')
			relatedLink.setAttribute('data-pos', newElemPos.toString())
		})
	}
	getPos(elemPos: number, newActivePos: number) {
		if (newActivePos === 1 && elemPos + 1 === this.carouselLimit) {
			return -2
		}
		if (newActivePos === -1 && elemPos == -2) {
			return this.carouselLimit - 1
		}
		if (newActivePos === 1 && elemPos < this.carouselLimit) return elemPos + 1
		if (newActivePos === -1 && elemPos > -2) return elemPos - 1
		else {
			throw new Error('Something went wrong, indexes dont match')
		}
	}
}
