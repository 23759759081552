import { safeDefineElement } from '@/scripts/core/global'
import { DeferredMedia } from '@/scripts/theme/deferred-media'
import { DetailsDisclosure } from '@/scripts/theme/details-disclosure'
import { SliderComponent } from '@/scripts/theme/slider-component'
import { SlideshowComponent } from '@/scripts/theme/slideshow-component'
import { StickyHeader } from '@/scripts/theme/sticky-header'
import { UcoastVideo } from '@/scripts/core/ucoast-video';
import {ProductInfo} from "@/scripts/product/product-info";
import {ProductForm} from "@/scripts/product/product-form";
import {ProductModal} from "@/scripts/product/product-modal";
import {MediaGallery} from "@/scripts/product/media-gallery";
import {VariantSelects} from "@/scripts/theme/variant-selects";
import {VariantRadios} from "@/scripts/theme/variant-radios";
import {ProductRecommendations} from "@/scripts/theme/product-recommendations";
import {QuickAddCard} from "@/scripts/product/quick-add-card";
import {FeaturedCollection} from "@/scripts/theme/featured-collection";
import {SliderCarousel} from "@/scripts/theme/slider-carousel";
import {ShopifyCheckoutWrapper} from "@/scripts/product/shopify-checkout-wrapper";
import {ProductTabs} from "@/scripts/product/product-tabs";
import {ProductStickyCta} from "@/scripts/product/product-sticky-cta";
import {FaqItem} from "@/scripts/theme/faq-item";
import {KlaviyoForm} from "@/scripts/theme/klaviyo-form";
import {KlaviyoPopup} from "@/scripts/theme/klaviyo-popup";
import {CartModal} from "@/scripts/theme/cart-modal";
import {KlaviyoOosPopup} from "@/scripts/theme/klaviyo-oos-popup";
import {KlaviyoOosForm} from "@/scripts/theme/klaviyo-oos-form";
safeDefineElement(UcoastVideo)
safeDefineElement(DeferredMedia)
safeDefineElement(SliderComponent)
safeDefineElement(SlideshowComponent)
safeDefineElement(StickyHeader)
safeDefineElement(DetailsDisclosure)
safeDefineElement(ProductInfo)
safeDefineElement(ProductForm)
safeDefineElement(ProductModal)
safeDefineElement(MediaGallery)
safeDefineElement(VariantSelects)
safeDefineElement(VariantRadios)
safeDefineElement(ProductRecommendations)
safeDefineElement(QuickAddCard)
safeDefineElement(FeaturedCollection)
safeDefineElement(SliderCarousel)
safeDefineElement(ShopifyCheckoutWrapper)
safeDefineElement(ProductTabs)
safeDefineElement(ProductStickyCta)
safeDefineElement(FaqItem)
safeDefineElement(KlaviyoForm)
safeDefineElement(KlaviyoPopup)
safeDefineElement(CartModal)
safeDefineElement(KlaviyoOosPopup)
safeDefineElement(KlaviyoOosForm)
