import { ProductModal } from '@/scripts/product/product-modal'
import {mediaLoader, qsOptional} from '@/scripts/core/global'

export class CartModal extends ProductModal {
	static override htmlSelector = 'cart-modal'
	currentProductHandle?: string
	constructor() {
		super()
	}
	loadAndShow(opener: HTMLElement, productHandle: string, mediaId: string) {
		if (productHandle === this.currentProductHandle) {
			this.show(opener, mediaId)
			return
		}
		this.currentProductHandle = productHandle
		const fetchUrl = `/products/${this.currentProductHandle}?section_id=product-media-modal`
		fetch(fetchUrl)
			.then((response) => response.text())
			.then((text) => {
				const html = new DOMParser().parseFromString(text, 'text/html')
				const modal = qsOptional<ProductModal>('product-modal', html)

				if (!modal) throw new Error('product modal not found')
				this.innerHTML = modal.innerHTML
				mediaLoader()
				this.show(opener, mediaId)
				this.querySelector('[id^="ModalClose-"]')?.addEventListener('click', this.hide.bind(this, false))
			})
			.catch((e) => {
				console.error(e)
			})
	}
}
