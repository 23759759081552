import { ModalDialog } from '@/scripts/theme/modal-dialog'
import { qsOptional, qsRequired } from '@/scripts/core/global'
import { DeferredMedia } from '@/scripts/theme/deferred-media'

export class ProductModal extends ModalDialog {
	static override htmlSelector = 'product-modal'
	constructor() {
		super()
	}
	static selectors = {
		container: '[data-uc-product-modal-container]',
	}

	override hide() {
		super.hide()
		qsRequired('html').classList.remove('media-modal-open')
	}

	override show(opener: HTMLElement, mediaId: string) {
		super.show(opener, mediaId)
		this.showActiveMedia()
		qsRequired('html').classList.add('media-modal-open')
	}

	showActiveMedia() {
		const openedById = this.openedBy ? this.openedBy.getAttribute('data-media-id') : 'NO_OPENER'
		this.querySelectorAll(`[data-media-id]:not([data-media-id="${openedById}"])`).forEach(
			(element) => {
				element.classList.remove('active')
			}
		)
		const activeMedia = qsOptional<
			HTMLImageElement | HTMLVideoElement | HTMLIFrameElement | DeferredMedia
		>(`[data-media-id="${openedById}"]`, this)
		if (!activeMedia) {
			return
		}
		const activeMediaTemplate = activeMedia.querySelector('template')
		const activeMediaContent = activeMediaTemplate ? activeMediaTemplate.content : null

		const container = qsRequired(ProductModal.selectors.container, this)
		const activeMediaWidth = activeMedia.width
			? parseInt(`${activeMedia.width}`)
			: activeMedia.clientWidth
		container.scrollLeft = (activeMediaWidth - container.clientWidth) / 2

		if (
			activeMedia instanceof DeferredMedia &&
			activeMediaContent &&
			activeMediaContent.querySelector('.js-youtube')
		)
			activeMedia.loadContent()
	}
}
