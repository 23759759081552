import { VariantSelects } from '@/scripts/theme/variant-selects'
import {getAttributeOrThrow, qsaOptional, qsRequired} from '@/scripts/core/global'

export class VariantRadios extends VariantSelects {
	static override htmlSelector = 'variant-radios'
	static override selectors = {
		...VariantSelects.selectors,
	}
	constructor() {
		super()
	}

	override setInstanceSelectors() {
		this.instanceSelectors = VariantRadios.selectors
	}

	override setInputAvailability(
		listOfOptions: (HTMLInputElement | HTMLOptionElement)[],
		listOfAvailableOptions: (string | null)[]
	) {
		listOfOptions.forEach((input) => {
			if (listOfAvailableOptions.includes(input.getAttribute('value'))) {
				input.classList.remove('disabled')
			} else {
				input.classList.add('disabled')
			}
		})
	}

	override updateOptions() {
		const fieldsetNodes = qsaOptional<HTMLFieldSetElement>('fieldset', this)
		if (!fieldsetNodes) throw new Error('cannot do updateOptions in variant-radios, no fieldset nodes found')
		const fieldsets = Array.from(fieldsetNodes)
		this.options = fieldsets.map((fieldset) => {
			if (fieldset.hasAttribute('data-uc-fieldset-color')) {
				return getAttributeOrThrow('data-uc-fieldset-color', fieldset)
			}
			const inputNodes = qsaOptional<HTMLInputElement>('input', fieldset)
			if (!inputNodes) throw new Error('cannot do updateOptions in variant-radios, no input nodes found')
			const inputs = Array.from(inputNodes)
			const checkedInput = inputs.find((radio) => radio.checked)
			if (!checkedInput) throw new Error('cannot do updateOptions in variant-radios, no checked input found')
			return checkedInput.value
		})
	}

	getSelectedOptions(): { name: string; value: string }[] {
		const fieldsetNodes = qsaOptional<HTMLFieldSetElement>('fieldset', this)
		if (!fieldsetNodes) throw new Error('cannot do updateOptions in variant-radios, no fieldset nodes found')
		const fieldsets = Array.from(fieldsetNodes).filter(fieldset => !fieldset.hasAttribute('data-uc-fieldset-color'))

		return fieldsets.map((fieldset) => {
			const inputNodes = qsaOptional<HTMLInputElement>('input', fieldset)
			if (!inputNodes) throw new Error('cannot do updateOptions in variant-radios, no input nodes found')
			const inputs = Array.from(inputNodes)
			const checkedInput = inputs.find((radio) => radio.checked)
			if (!checkedInput) throw new Error('cannot do updateOptions in variant-radios, no checked input found')

			return {
				name: getAttributeOrThrow('name', checkedInput),
				value: checkedInput.value,
			}
		})
	}

	updateOptionsFromExternalSource(options: { name: string; value: string }[]) {
      options.forEach(option => {
        const selectedInput = qsRequired<HTMLInputElement>(`input[name="${option.name}"][value="${option.value}"]`, this)
        selectedInput.checked = true
      })
      this.onVariantChange(new Event('externalVariantUpdate'), true)

    }
}
