import {getAttributeOrThrow, qsaRequired, qsRequired, trapFocus} from '@/scripts/core/global'
import { UcoastEl } from '@/scripts/core/UcoastEl'

type TabItem = {
	navItem: HTMLElement
	contentItem: HTMLElement
}

export class ProductTabs extends UcoastEl {
	static htmlSelector = 'product-tabs'
	// add typescript types for this.navItems etc
	navItems: NodeListOf<HTMLElement>
	navContainer: HTMLElement
	contentItems: NodeListOf<HTMLElement>
	contentContainer: HTMLElement

	constructor() {
		super()
		this.navItems = qsaRequired('[data-tab-nav]', this)
		this.navContainer = qsRequired('[data-tab-nav-container]', this)
		this.contentItems = qsaRequired('[data-tab-content]', this)
		this.contentContainer = qsRequired('[data-tabs-container]', this)

		if (!this.navItems || !this.contentItems) return

		this.initItems()
		const resizeObserver = new ResizeObserver((_: ResizeObserverEntry[]) => this.initItems())
		resizeObserver.observe(this.contentContainer)

		for (let i = 0; i < this.navItems.length; i++) {
			this.navItems[i].addEventListener('click', this.onButtonClick.bind(this))
		}
	}

	initItems() {
		this.classList.add('loaded')
	}

	getSelectedItem(i: number): TabItem {
		if (!this.navItems[i] || !this.contentItems[i]) throw new Error('Tab item not found')
		return {
			navItem: this.navItems[i],
			contentItem: this.contentItems[i],
		}
	}

	setActive(item: TabItem) {
		item.navItem.setAttribute('aria-current', 'true')
		item.contentItem.style.maxHeight = `${item.contentItem.scrollHeight}px`
		item.contentItem.setAttribute('aria-expanded', 'true')
		trapFocus(item.contentItem, item.navItem)
	}

	setHidden(item: TabItem) {
		item.navItem.removeAttribute('aria-current')
		item.contentItem.style.maxHeight = `0px`
		item.contentItem.removeAttribute('aria-expanded')
	}

	onButtonClick(event: MouseEvent | TouchEvent) {
		event.preventDefault()
		if (!event.currentTarget || !(event.currentTarget instanceof HTMLElement)) return
		const id = getAttributeOrThrow('data-tab', event.currentTarget)

		this.navItems.forEach((el: HTMLElement, i: number) => {
			const item = this.getSelectedItem(i)
			if (el.getAttribute('data-tab') !== id) {
				this.setHidden(item)
				return
			}

			this.setActive(item)
		})
	}
}
