import { ModalDialog } from '@/scripts/theme/modal-dialog'
import { KlaviyoForm } from '@/scripts/theme/klaviyo-form'
import {getAttributeOrThrow, mediaLoader, qsRequired} from '@/scripts/core/global'

export class KlaviyoPopup extends ModalDialog {
	static override htmlSelector = 'klaviyo-popup'
	klaviyoForm: KlaviyoForm
	openAfter: number
	cacheKey: string
	openers?: NodeListOf<HTMLButtonElement>
	demoMode: boolean
	delayForAnimation: boolean
	constructor() {
		super()
		this.delayForAnimation = document.documentElement.classList.contains('pre-animation')
		this.preloaded = true
		this.klaviyoForm = qsRequired('klaviyo-form', this)
		this.openAfter = parseInt(getAttributeOrThrow('data-uc-open-after', this)) * 1000
		if (this.delayForAnimation) {
			this.openAfter = this.openAfter + 10000;
		}
		this.cacheKey = getAttributeOrThrow('data-uc-cache-key', this)
		this.demoMode = getAttributeOrThrow('data-uc-demo-mode', this) === 'true'
	}

	initializePopup() {
		if (this.isKeyDisabled() && !this.demoMode) return
		window.setTimeout(() => {
			this.show()
			mediaLoader()
		}, this.openAfter)
	}

	override connectedCallback() {
		this.initializePopup()
	}

	override show(opener?: HTMLButtonElement) {
		this.removeAttribute('style')
		window.setTimeout(() => {
			super.show(opener)
		}, 2)
	}

	override hide() {
		super.hide()
		this.disableKey()
	}

	isKeyDisabled() {
		const disabledKey = window.localStorage.getItem('disable_klaviyo')
		if (!disabledKey) return false
		return disabledKey === this.cacheKey
	}

	disableKey() {
		window.localStorage.setItem('disable_klaviyo', this.cacheKey)
	}
}
