import { UcoastEl } from '@/scripts/core/UcoastEl'

export class ShopifyCheckoutWrapper extends UcoastEl {
	static htmlSelector = 'shopify-checkout-wrapper'
	private observer: MutationObserver | null = null

	constructor() {
		super()
	}

	override connectedCallback() {
		super.connectedCallback()
		this.initializeObserver()
	}

	override disconnectedCallback() {
		super.disconnectedCallback()
		this.disconnectObserver()
	}

	private initializeObserver() {
		const config = { childList: true, subtree: true }
		const callback = (mutationsList: MutationRecord[], observer: MutationObserver) => {
			for (let mutation of mutationsList) {
				if (mutation.type === 'childList') {
					// Check if the third-party custom element has been loaded
					const customElement = document.querySelector('shopify-payment-terms')
					const banner = customElement?.shadowRoot?.querySelector('shop-pay-installments-banner')
					const shopPayLogo = banner?.shadowRoot?.querySelector('shop-pay-logo')
					if (shopPayLogo) {
						// Create and insert the style tag into the Shadow DOM
						const shopPayLogoShadow = shopPayLogo.shadowRoot
						if (shopPayLogoShadow) {

						}
						if (banner) {
							const bannerShadow = banner.shadowRoot

							if (bannerShadow) {
								const style = document.createElement('style')
								style.textContent = `
							.shopify-installments__content {
								padding-right: 0;
							}
							.shopify-installments__content b {
							 font-weight: 400;
							}
							
                            shop-pay-logo {
                              filter: grayscale(100%) brightness(0);
                            }
							`
								bannerShadow.appendChild(style)
								const link = bannerShadow.querySelector('#shopify-installments-cta')
								if (link) {
									link.innerHTML = 'Learn more'
								}
							}
						}
						// Stop observing once we've found the custom element and styled it
						observer.disconnect()
						this.observer = null
						document
							.querySelectorAll('shopify-checkout-wrapper')
							?.forEach((el) => el.classList.add('active'))
						break
					}
				}
			}
		}

		this.observer = new MutationObserver(callback)

		// Start observing the target node for configured mutations
		this.observer.observe(this, config)
	}

	private disconnectObserver() {
		// Stop observing when this element is disconnected from the DOM
		if (this.observer) {
			this.observer.disconnect()
			this.observer = null
		}
	}
}
