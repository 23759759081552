import {ModalDialog} from "@/scripts/theme/modal-dialog";
import {mediaLoader, qsRequired, qsRequiredFromDocument} from '@/scripts/core/global'

export class KlaviyoOosPopup extends ModalDialog {
    static override htmlSelector = 'klaviyo-oos-popup'
    dynamicContentWrapper: HTMLElement
    constructor() {
        super()
        this.preloaded = true
        this.dynamicContentWrapper = qsRequired('[data-uc-klaviyo-oos-popup-content]', this)
    }

    override connectedCallback() {

    }

    async loadSectionContent(opener: HTMLButtonElement, productHandle: string, productVariantId: string) {
        const response = await fetch(`/products/${productHandle}?variant=${productVariantId}` + "&sections=klaviyo-oos-popup")
            .then(res => res.json())

        const html = new DOMParser().parseFromString(response['klaviyo-oos-popup'], 'text/html')
        const newContent = qsRequiredFromDocument('#DynamicContent', html)
        this.dynamicContentWrapper.innerHTML = newContent.innerHTML
        this.show(opener)
    }

    override show(opener?: HTMLButtonElement) {
        this.removeAttribute('style')
        window.setTimeout(() => {
            super.show(opener)
            mediaLoader()
        }, 2)
    }

    override hide() {
        super.hide()
    }
}

