import { UcoastEl } from '@/scripts/core/UcoastEl'
import { qsRequired } from '@/scripts/core/global'

export class FaqItem extends UcoastEl {
	static htmlSelector = 'faq-item'
	question: HTMLButtonElement
	answer: HTMLElement
	constructor() {
		super()
		this.question = qsRequired('[data-uc-faq-question', this)
		this.answer = qsRequired('[data-uc-faq-answer]', this)
		this.question.addEventListener('click', () => {
			if (this.question.getAttribute('aria-expanded') === 'false') {
				this.show()
			} else {
				this.hide()
			}
		})
	}

	show() {
		this.question.setAttribute('aria-expanded', 'true')
		this.answer.setAttribute('aria-hidden', 'false')
		this.answer.style.maxHeight = `${this.answer.scrollHeight}px`
	}
	hide() {
        this.question.setAttribute('aria-expanded', 'false')
        this.answer.setAttribute('aria-hidden', 'true')
        this.answer.style.maxHeight = `0px`
    }
}
