import { getAttributeOrThrow, qsaRequired, qsRequired, trapFocus } from '@/scripts/core/global'
import { UcoastEl } from '@/scripts/core/UcoastEl'

type FeaturedCollectionSlider = {
	id: string
	button: HTMLButtonElement
	container: HTMLElement
	collectionUrl: string
	collectionTitle: string
}

export class FeaturedCollection extends UcoastEl {
	static htmlSelector = 'featured-collection'
	activeSlider = 1
	sectionId: string
	slider1: FeaturedCollectionSlider
	slider2?: FeaturedCollectionSlider
	slider1Id: string
	slider2Id?: string
	viewAllButtons: NodeListOf<HTMLButtonElement>
	viewAllTitleEls: NodeListOf<HTMLElement>
	constructor() {
		super()
		this.sectionId = getAttributeOrThrow('data-uc-featured-collection-id', this)
		this.slider1Id = getAttributeOrThrow('data-uc-featured-collection-slider-1-id', this)
		this.slider1 = this.getSlider(this.slider1Id)
		this.slider2Id = this.getAttribute('data-uc-featured-collection-slider-2-id') ?? undefined
		if (this.slider2Id) {
			this.slider2 = this.getSlider(this.slider2Id)
		}
		this.viewAllButtons = qsaRequired(`[data-uc-featured-collection-view-all-id="${this.sectionId}"]`, this)
		this.viewAllTitleEls = qsaRequired(`[data-uc-featured-collection-view-all-title-id="${this.sectionId}"]`, this)
		this.initNav()
	}

	getSlider(sliderId: string) {
		return {
			id: sliderId,
			button: qsRequired<HTMLButtonElement>(`[data-uc-featured-collection-button-id="${sliderId}"]`, this),
			container: qsRequired(`[data-uc-featured-collection-slider-id="${sliderId}"]`, this),
			collectionUrl: getAttributeOrThrow(`data-uc-featured-collection-url-${sliderId}`, this),
			collectionTitle: getAttributeOrThrow(`data-uc-featured-collection-title-${sliderId}`, this),
		}
	}
	initNav() {
		const slider2 = this.slider2
		if (!slider2) return
		this.slider1.button.addEventListener('click', () => {
			this.toggleSlider(this.slider1, slider2)
		})
		slider2.button.addEventListener('click', () => {
			this.toggleSlider(slider2, this.slider1)
		})
	}

	toggleSlider(showSlider: FeaturedCollectionSlider, hideSlider: FeaturedCollectionSlider) {
		showSlider.button.classList.add('active')
		showSlider.container.classList.add('active')
		hideSlider.button.classList.remove('active')
		hideSlider.container.classList.remove('active')
		trapFocus(showSlider.container, showSlider.button)
		this.viewAllButtons.forEach((button) => button.setAttribute('href', showSlider.collectionUrl))
		this.viewAllTitleEls.forEach((titleEl) => (titleEl.textContent = showSlider.collectionTitle))
	}
}
