import { VariantSelects } from '@/scripts/theme/variant-selects'
import {
	createVariantChangeEvent,
	getAttributeOrThrow,
	PUB_SUB_EVENTS,
	publish,
	qsaOptional,
	qsaRequired,
	qsOptional,
	qsRequired,
} from '@/scripts/core/global'
import { uCoastWindow } from '@/scripts/setup'
import { QuickAddModal } from '@/scripts/optional/quick-add'

declare let window: uCoastWindow

export class QuickAddCard extends VariantSelects {
	static override htmlSelector = 'quick-add-card'
	static override selectors = {
		...VariantSelects.selectors,
		sliderContainer: '[data-uc-quick-add-slider]',
		sliderTrack: '[data-uc-quick-add-slider-track]',
		sliderLabels: '[data-uc-quick-add-slider-label]',
		sliderInputs: '[data-uc-quick-add-slider-input]',
		sliderNextbutton: '[data-uc-quick-add-slider-next]',
		sliderPrevbutton: '[data-uc-quick-add-slider-prev]',
		closeModalButton: '[data-uc-quick-add-close-modal]',
	}
	sliders?: {
		sliderContainers: HTMLElement[]
		sliderTracks: HTMLElement[]
		sliderLabels: HTMLLabelElement[]
		sliderInputs: HTMLInputElement[]
		sliderNextbuttons: HTMLButtonElement[]
		sliderPrevbuttons: HTMLButtonElement[]
	}
	closeModalButton: HTMLButtonElement
	parentModal?: QuickAddModal

	constructor() {
		super()
		this.sliders = this.getAllSliders()
		this.closeModalButton = qsRequired<HTMLButtonElement>(
			`${this.instanceSelectors.closeModalButton}[data-uc-product-id="${this.productId}"]`
		)
		this.parentModal = qsRequired<QuickAddModal>(`quick-add-modal[data-uc-product-id="${this.productId}"]`)
	}

	override connectedCallback() {
		super.connectedCallback()
		this.isQuickAdd = true
		this.closeModalButton.addEventListener('click', (event: Event) => {
			event.preventDefault()
			if (!this.parentModal) {
				throw new Error('Close modal button does not have a parent modal')
			}
			this.parentModal.hide()
		})
		this.addEventListener('change', this.onVariantChange)
		if (this.sliders) {
			this.setSliderPosition()
			this.setSliderButtonListeners()
		}
	}

	// slider functionality
	getAllSliders() {
		const sliderContainer = qsOptional(this.instanceSelectors.sliderContainer, this)
		if (!sliderContainer) return undefined
		return {
			sliderContainers: Array.from(qsaRequired(this.instanceSelectors.sliderContainer, this)),
			sliderTracks: Array.from(qsaRequired(this.instanceSelectors.sliderTrack, this)),
			sliderLabels: Array.from(qsaRequired<HTMLLabelElement>(this.instanceSelectors.sliderLabels, this)),
			sliderInputs: Array.from(qsaRequired<HTMLInputElement>(this.instanceSelectors.sliderInputs, this)),
			sliderNextbuttons: Array.from(
				qsaRequired<HTMLButtonElement>(this.instanceSelectors.sliderNextbutton, this)
			),
			sliderPrevbuttons: Array.from(
				qsaRequired<HTMLButtonElement>(this.instanceSelectors.sliderPrevbutton, this)
			),
		}
	}

	getCurrentSliderIndexes() {
		if (!this.sliders) throw new Error('should not have been called without slider')
		const checkedInput = this.sliders.sliderInputs.find((input) => input.checked)
		if (!checkedInput) throw new Error('no checked input found')
		const checkedInputIndex = getAttributeOrThrow('data-uc-quick-add-slider-input', checkedInput)
		const sliderIndex = getAttributeOrThrow('data-uc-quick-add-slider-index', checkedInput)
		return {
			checkedInputIndex,
			sliderIndex,
		}
	}

	getCurrentSliderElements(checkedInputIndex: string, sliderIndex: string) {
		if (!this.sliders) throw new Error()
		const currentLabel = this.sliders.sliderLabels.find((label) => {
			const inSlider = getAttributeOrThrow('data-uc-quick-add-slider-label', label) === checkedInputIndex
			const isSelected = getAttributeOrThrow('data-uc-quick-add-slider-index', label) === sliderIndex
			return inSlider && isSelected
		})
		if (!currentLabel) throw Error('no current label found')
		const currentTrack = this.sliders.sliderTracks.find(
			(track) => getAttributeOrThrow('data-uc-quick-add-slider-track', track) === sliderIndex
		)
		if (!currentTrack) throw Error('no current track found')

		const sliderContainer = this.sliders.sliderContainers.find(
			(container) => getAttributeOrThrow('data-uc-quick-add-slider', container) === sliderIndex
		)
		if (!sliderContainer) throw Error('no slider container found')
		return {
			currentLabel,
			currentTrack,
			sliderContainer,
		}
	}

	setSliderPosition() {
		if (!this.sliders) throw new Error('should not have been called without slider')
		const { checkedInputIndex, sliderIndex } = this.getCurrentSliderIndexes()
		const { currentLabel, currentTrack, sliderContainer } = this.getCurrentSliderElements(
			checkedInputIndex,
			sliderIndex
		)

		currentTrack.style.transform = `translateX(${
			-1 * currentLabel.offsetLeft -
			currentTrack.offsetLeft +
			sliderContainer.clientWidth / 2 -
			currentLabel.clientWidth / 2
		}px)`
	}

	setSliderButtonListeners() {
		if (!this.sliders) throw Error('should not have been called without slider')
		this.sliders.sliderNextbuttons.forEach((button) => {
			button.addEventListener('click', this.onSliderButtonNext.bind(this))
		})
		this.sliders.sliderPrevbuttons.forEach((button) => {
			button.addEventListener('click', this.onSliderButtonPrev.bind(this))
		})
	}

	onSliderButtonNext(event: Event) {
		event.preventDefault()
		if (!this.sliders) throw Error('should not have been called without slider')
		const { checkedInputIndex, sliderIndex } = this.getCurrentSliderIndexes()

		const currentSliderInputs = this.sliders.sliderInputs.filter(
			(input) => getAttributeOrThrow('data-uc-quick-add-slider-index', input) === sliderIndex
		)
		// if last input in slider, do nothing
		if (parseInt(checkedInputIndex) >= currentSliderInputs.length - 1) return

		const nextIndex = `${parseInt(checkedInputIndex) + 1}`
		this.selectInput(nextIndex, sliderIndex)
	}

	onSliderButtonPrev(event: Event) {
		event.preventDefault()
		if (!this.sliders) throw Error('should not have been called without slider')
		const { checkedInputIndex, sliderIndex } = this.getCurrentSliderIndexes()

		// do nothing if first input in slider
		if (checkedInputIndex === '0') return

		const prevIndex = `${parseInt(checkedInputIndex) - 1}`
		this.selectInput(prevIndex, sliderIndex)
	}

	selectInput(newIndex: string, sliderIndex: string) {
		if (!this.sliders) throw new Error('should not have been called without slider')
		const labelToSelect = this.sliders.sliderLabels.find(
			(label) =>
				getAttributeOrThrow('data-uc-quick-add-slider-label', label) === newIndex &&
				getAttributeOrThrow('data-uc-quick-add-slider-index', label) === sliderIndex
		)

		if (!labelToSelect) throw Error('no input to select found')

		labelToSelect.click()
	}

	// below is mostly copied from variant-radios and has some method overrides on base class

	override onVariantChange(event: Event) {
		super.onVariantChange(event)
		this.setSliderPosition()
	}

	override renderProductInfo() {
		if (!this.currentVariant)
			throw new Error('this.currentVariant not selected, renderProductInfo called too early')
		const requestedVariantId = this.currentVariant.id
		const originalSectionId = getAttributeOrThrow('data-original-section', this)
		const productSectionId = getAttributeOrThrow('data-section', this)

		fetch(`${this.dataset.url}?variant=${requestedVariantId}&section_id=${originalSectionId}`)
			.then((response) => response.text())
			.then((responseText) => {
				if (!this.currentVariant)
					throw new Error('this.currentVariant not selected, renderProductInfo called too early')
				// prevent unnecessary ui changes from abandoned selections
				if (this.currentVariant.id !== requestedVariantId) return

				const html = new DOMParser().parseFromString(responseText, 'text/html')

				const addButtonUpdated = html.getElementById(`ProductSubmitButton-${productSectionId}`)
				this.toggleAddButton(
					addButtonUpdated ? addButtonUpdated.hasAttribute('disabled') : true,
					window.variantStrings.soldOut
				)

				publish(
					PUB_SUB_EVENTS.variantChange,
					createVariantChangeEvent({
						source: 'product-form',
						data: {
							sectionId: productSectionId,
							html,
							variant: this.currentVariant,
						},
					})
				)
			})
	}

	override setInstanceSelectors() {
		this.instanceSelectors = QuickAddCard.selectors
	}

	override setInputAvailability(
		listOfOptions: (HTMLInputElement | HTMLOptionElement)[],
		listOfAvailableOptions: (string | null)[]
	) {
		listOfOptions.forEach((input) => {
			if (listOfAvailableOptions.includes(input.getAttribute('value'))) {
				input.classList.remove('disabled')
			} else {
				input.classList.add('disabled')
			}
		})
	}

	override updateOptions() {
		const fieldsetNodes = qsaOptional<HTMLFieldSetElement>('fieldset', this)
		if (!fieldsetNodes) throw new Error('cannot do updateOptions in variant-radios, no fieldset nodes found')
		const fieldsets = Array.from(fieldsetNodes)
		this.options = fieldsets.map((fieldset) => {
			const inputNodes = qsaOptional<HTMLInputElement>('input', fieldset)
			if (!inputNodes) throw new Error('cannot do updateOptions in variant-radios, no input nodes found')
			const inputs = Array.from(inputNodes)
			const checkedInput = inputs.find((radio) => radio.checked)
			if (!checkedInput) throw new Error('cannot do updateOptions in variant-radios, no checked input found')
			return checkedInput.value
		})
	}
}
