import { ProductInfo } from '@/scripts/product/product-info'
import {
	currentTargetRequired,
	qsOptional,
	qsRequired,
	targetRequired,
	trapFocus,
} from '@/scripts/core/global'

export class ProductStickyCta extends ProductInfo {
	static override htmlSelector = 'product-sticky-cta'
	mobileToggleButton: HTMLButtonElement
	form: HTMLElement
	wrapper: HTMLElement
	submitContainer: HTMLElement
	overlay: HTMLElement
	boundBodyListener: () => void
	cta: HTMLButtonElement
	primaryCta: HTMLButtonElement
	info: ProductInfo
	constructor() {
		super()
		this.mobileToggleButton = qsRequired('[data-uc-sticky-mobile-toggle]', this)
		this.form = qsRequired('[data-uc-sticky-form]', this)
		this.wrapper = qsRequired('[data-uc-sticky-wrapper]', this)
		this.overlay = qsRequired('[data-uc-sticky-overlay]', this)
		this.submitContainer = qsRequired('[data-uc-sticky-submit-container', this)
		this.cta = qsRequired('button[type="submit"]', this.submitContainer)
		this.boundBodyListener = this.bodyListener.bind(this)
		this.mobileToggleButton.addEventListener('click', this.revealForm.bind(this))
		this.primaryCta = qsRequired('button[type="submit"][data-source="primary"]')
		this.info = qsRequired('product-info')
		this.observePrimaryCta(this.primaryCta, this.onPrimaryCtaIntersection.bind(this))
	}
	override getSiblingForm() {
		return qsOptional<ProductStickyCta>(`product-info[data-uc-product-id="${this.productId}"]`)
	}
	revealForm(event: Event) {
		event.preventDefault()
		this.form.setAttribute('aria-hidden', 'false')
		this.form.style.maxHeight = `${this.form.scrollHeight}px`
		this.mobileToggleButton.setAttribute('aria-expanded', 'true')
		this.mobileToggleButton.style.display = 'none'
		this.overlay.removeAttribute('style')
		trapFocus(this.form, this.mobileToggleButton)
		window.setTimeout(() => {
			this.overlay.classList.add('active')
			document.documentElement.addEventListener('click', this.boundBodyListener)
		}, 1)
	}
	observePrimaryCta(
		cta: HTMLElement,
		onIntersection: (elements: IntersectionObserverEntry[], _: IntersectionObserver) => void
	) {
		const ctaObserver = new IntersectionObserver(onIntersection)
		ctaObserver.observe(cta)
	}
	onPrimaryCtaIntersection(elements: IntersectionObserverEntry[], _: IntersectionObserver) {
		elements.forEach((element) => {
			if (element.isIntersecting) {
				this.hide()
			} else if (window.scrollY > 500) {
				this.show()
			}
		})
	}
	hide() {
		this.setAttribute('aria-hidden', 'true')
		//removeTrapFocus()
	}
	show() {
		this.setAttribute('aria-hidden', 'false')
		//trapFocus(this.form, this.cta)
	}
	hideForm(event: Event) {
		event.preventDefault()
		this.form.setAttribute('aria-hidden', 'true')
		this.form.style.maxHeight = '0px'
		this.overlay.classList.remove('active')
		window.setTimeout(() => {
			this.mobileToggleButton.removeAttribute('style')
			this.mobileToggleButton.setAttribute('aria-expanded', 'false')
			this.overlay.style.display = 'none'
		}, 301)
		document.documentElement.removeEventListener('click', this.boundBodyListener)
	}
	bodyListener(event: Event) {
		const currentTarget = currentTargetRequired(event)
		const target = targetRequired(event)
		if (
			this.variantRadios?.contains(currentTarget) ||
			this.variantRadios?.contains(target) ||
			this.submitContainer.contains(target) ||
			this.submitContainer.contains(currentTarget)
		) {
			return
		}
		this.hideForm(event)
	}
}
